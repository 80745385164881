import {ClientName} from './Components/ClientName.jsx';
import './App.css';
import {useState} from 'react';
import TestForm from './Components/TestForm'
function App() {
  const [firstName, setFirstName] = useState('FirstName');
  const [lastName, setLastName] = useState('LastName');
  return (

    <div>
      <input className = 'Bg-blue' type='text' onChange={(firstName) => {setFirstName(firstName.target.value);}} />
      <input className = 'Bg-red' type='text' onChange={(lastName) => {setLastName(lastName.target.value);}} />
      <ClientName firstName = {firstName} lastName = {lastName} />
      <TestForm />
    </div>
  );
}

export default App;
